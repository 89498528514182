<template>
  <div :style="platform.includes('desktop') || platform.includes('mobileweb') ? 'min-height: 60px;' : 'min-height: 100px;'">
    <ion-toolbar>
      <ion-buttons
        v-if="$store.getters.loggedIn"
        slot="start"
        class="profile-image"
      >
        <ion-menu-toggle auto-hide="false">
          <ion-img :src="require('../../assets/images/shop-logo.svg')" />
        </ion-menu-toggle>
      </ion-buttons>
      <ion-button
        v-if="!$store.getters.loggedIn"
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="$router.push('signup')"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-buttons
        v-if="!$store.getters.loggedIn"
        slot="start"
        class="profile-image"
      >
        <ion-img :src="require('../../assets/images/shop-logo.svg')" />
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button
          :disabled="disableCart"
          class="notification"
          color="transparent"
          @click="openCart"
        >
          <i
            class="mdi mdi-cart text--white"
            style="font-size: 25px;"
          />
          <ion-badge
            style="font-size: 10px; margin-top: 4px; padding: 4px; border-radius: 30%;"
            class="text--dark"
          >
            {{ howManyInCart }}
          </ion-badge>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </div>
</template>

<script>
// import { modalController } from '@ionic/vue'

// import cartModal from '../Eshop/Cart.vue'

export default {
  components: {
  },
  props: {
    cart: { type: Array, default: () => [] },
    disableCart: { type: Boolean, default: false },
    products: { type: Array, default: () => [] },
    openCart: { type: Function, default: null }
  },
  data () {
    return {
    }
  },
  computed: {
    platform () {
      console.log(this.$store.state.platform)
      return this.$store.state.platform
    },
    notificationCount () {
      const notifications = this.$store.state.notification
      let count = 0
      notifications.forEach((element) => {
        if (element) { count++ }
      })
      return count
    },
    gatewayProgress () {
      return this.$store.state.gatewayProgress
    },
    currentRouteName () {
      return this.$route.name
    },
    howManyInCart () {
      let result = 0
      this.cart.forEach(i => {
        result = result + i.qty
      })
      return result
    }
  },
  watch: {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  ion-header {
    ion-toolbar {
      // padding:10px 0;
      position: fixed;
      z-index: 9;
      width: 100%;
      --border-style: unset !important;
    }
    ion-title {
      font-weight: 300;
      letter-spacing: 0.6px;
    }
    .notification {
        padding-right: 10px;
    ion-badge {
      position: absolute;
      top: 0px;
      font-size: 10px;
      padding: 4px;
      right: -5px;
    }
    }
  }
  ion-header,
  ion-toolbar,
  ion-content {
    --background: none;
    color: #fff;

    ion-icon {
      color: #fff;
    }
  }
  .profile-image {
    padding-left: 10px;
    padding-top: 5px;
    ion-avatar {
      max-width: 35px;
      max-height: 35px;
    }
  }
</style>